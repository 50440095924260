<h1 mat-dialog-title>Permissions for <strong>{{ group.title }}</strong> group</h1>
<form #addPermissionsForm="ngForm" class="permissions-form" (ngSubmit)="addPermissions()">
    <mat-dialog-content>
        <app-alert *ngIf="connectionAccess === 'edit' && group.title === 'Admin'" [alert]="adminGroupAlert"></app-alert>
        <app-alert *ngIf="connectionAccess === 'edit' && group.title !== 'Admin'" [alert]="connectionFullAccessAlert"></app-alert>

        <div class="permissions">
            <h2 class="mat-subtitle-1 permissions__title">Connection credentials</h2>
            <mat-button-toggle-group name="connection-permissions"
                class="permissions-toggle-group"
                [disabled]="connectionAccess === 'edit' && group.title === 'Admin'"
                [(ngModel)]="connectionAccess"
                (ngModelChange)="handleConnectionAccessChange()">
                <mat-button-toggle name="connection-none" value="none">None</mat-button-toggle>
                <mat-button-toggle name="connection-readonly" value="readonly">ReadOnly</mat-button-toggle>
                <mat-button-toggle name="connection-full-access" value="edit">Full access</mat-button-toggle>
            </mat-button-toggle-group>

            <h2 class="mat-subtitle-1 permissions__title">User management</h2>
            <mat-button-toggle-group name="users-permissions"
                class="permissions-toggle-group"
                [disabled]="connectionAccess === 'edit' || group.title === 'Admin'"
                [(ngModel)]="groupAccess">
                <mat-button-toggle name="users-none" value="none">None</mat-button-toggle>
                <mat-button-toggle name="users-readonly" value="readonly">ReadOnly</mat-button-toggle>
                <mat-button-toggle name="users-full-access" value="edit">Manage the list</mat-button-toggle>
            </mat-button-toggle-group>

            <h2 class="mat-subtitle-1 permissions__title">Tables</h2>
            <span *ngIf="connectionAccess === 'edit' || group.title === 'Admin'">Full access</span>
            <div class="tables-options">
                <button mat-button type="button" *ngIf="!(connectionAccess === 'edit' || group.title === 'Admin')"
                    (click)="grantFullTableAccess()">
                        Full access
                </button>

                <button mat-button type="button" *ngIf="!(connectionAccess === 'edit' || group.title === 'Admin')"
                    (click)="deselectAllTables()">
                        Clear all
                </button>
            </div>

            <app-content-loader *ngIf="loading"></app-content-loader>
            <div class="tables-list">
                <div *ngIf="connectionAccess === 'edit'" class="tables-overlay"></div>
                <div>Visibility</div>
                <div class="table-name-title">Table name</div>
                <div>ReadOnly</div>
                <div>Add</div>
                <div>Delete</div>
                <div>Edit</div>
                <ng-container *ngFor="let table of tablesAccess">
                    <div class="tableVisibility">
                        <input type="checkbox" name="{{table.tableName}}-visibility" id="{{table.tableName}}-visibility"
                            class="visually-hidden"
                            (change)="uncheckActions(table)"
                            [(ngModel)]="table.accessLevel.visibility">
                        <label for="{{table.tableName}}-visibility">
                            <mat-icon class="visibilityIcon" [ngClass]="{'visibilityIcon_visible' : table.accessLevel.visibility}"
                                matTooltip="{{ table.accessLevel.visibility ? 'Table visible' : 'Table invisible' }}">
                                {{ table.accessLevel.visibility ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                        </label>
                    </div>

                    <div class="table-name" [ngClass]="{'table-name_disabled': !table.accessLevel.visibility}">
                        <span class="table-name__title">{{table.dispaleyName}}</span>
                        <span class="table-name__line">{{table.tableName}}</span>
                    </div>

                    <mat-slide-toggle name="{{table.tableName}}-readonly-toggle"
                        [disabled]="!table.accessLevel.visibility"
                        (change)="uncheckActions(table)"
                        [(ngModel)]="table.accessLevel.readonly">
                    </mat-slide-toggle>
                    <mat-checkbox name="{{table.tableName}}-add"
                        [disabled]="!table.accessLevel.visibility || table.accessLevel.readonly"
                        [(ngModel)]="table.accessLevel.add">
                    </mat-checkbox>
                    <mat-checkbox name="{{table.tableName}}-delete"
                        [disabled]="!table.accessLevel.visibility || table.accessLevel.readonly"
                        [(ngModel)]="table.accessLevel.delete">
                    </mat-checkbox>
                    <mat-checkbox name="{{table.tableName}}-edit"
                        [disabled]="!table.accessLevel.visibility || table.accessLevel.readonly"
                        [(ngModel)]="table.accessLevel.edit">
                    </mat-checkbox>
                </ng-container>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="group.title !== 'Admin'; else adminActions" align="end">
        <button mat-flat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary"
            [disabled]="submitting || addPermissionsForm.form.invalid">
                Update
        </button>
    </mat-dialog-actions>
    <ng-template #adminActions>
        <mat-dialog-actions align="end">
            <button mat-flat-button mat-dialog-close>Close</button>
        </mat-dialog-actions>
    </ng-template>

</form>